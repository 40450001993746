import React, { useEffect } from "react"
import { RouterProps } from "react-router"
import Leaflet, { marker } from "leaflet"
import { mapClass } from "./css"
import { useSelector, useDispatch } from "react-redux"
import { IReduxStore } from "../../redux/reducers"
import { getVessels } from "../../redux/actions/vessels"

const GreceCoords = {
  lat: 37.98381,
  lng: 23.727539
}

interface IProps extends RouterProps {}

function Map(props: IProps) {
  const _vessels = useSelector((state: IReduxStore) => state.vessels)

  const vessels = _vessels.filter(v => v.devices && v.devices.length > 0) || []
  const dispatch = useDispatch()

  useEffect(() => {
    const polling = setInterval(() => {
      dispatch(getVessels())
    }, 10000)
    dispatch(getVessels())
    return () => clearInterval(polling)
  }, [])

  useEffect(() => {
    const vessel = vessels[0]
    const position =
      (vessel &&
        vessel.devices &&
        vessel.devices[0] &&
        vessel.devices[0].telematicsData &&
        vessel.devices[0].telematicsData.position) ||
      {}
    const pos = {
      latitude: position.latitude,
      longitude: position.longitude
    }

    const [lat, lng] = [
      pos.latitude || GreceCoords.lat,
      pos.longitude || GreceCoords.lng
    ]
    const map = Leaflet.map("mapid").setView([lat, lng], 10)

    Leaflet.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {}
    ).addTo(map)

    vessels.forEach((v, i) => {
      const position =
        v.devices &&
        v.devices[0] &&
        v.devices[0].telematicsData &&
        v.devices[0].telematicsData.position

      if (!position || !position.latitude || !position.longitude) return

      const pos = {
        latitude: position.latitude,
        longitude: position.longitude
      }

      const marker = new Leaflet.Marker([pos.latitude, pos.longitude], {
        title: `${v.name}_${i}`
      }).addTo(map)

      marker.bindPopup(`<b>${v.name}</b>`).openPopup()
    })

    const allLatLng: [number, number][] = vessels.map((v, i) => {
      const position =
        v.devices &&
        v.devices[0] &&
        v.devices[0].telematicsData &&
        v.devices[0].telematicsData.position

      return (
        position &&
        position.latitude &&
        position.longitude && [position.latitude, position.longitude]
      )
    })

    if (allLatLng.filter(e => e).length > 0) {
      const bounds = new Leaflet.LatLngBounds(allLatLng)
      map.fitBounds(bounds)
    }
  }, [])

  return <div className={mapClass} id="mapid" />
}
export default Map
