import React from "react"
import { ILayout } from "../../Layout"
import { cx } from "emotion"
import { container, tab } from "./css"
import { Link } from "react-router-dom"
import { RouterProps } from "react-router"

interface IProps extends ILayout, RouterProps {}
function Footer(props: IProps) {
  const { pathname } = props.history.location
  return (
    <div className={cx(props.layoutClass, container)}>
      <Link to="/" className={cx(tab, { active: pathname === "/" })}>
        <img src="/images/menu_list.svg" alt="menu" />
      </Link>
      <Link to="/map" className={cx(tab, { active: pathname === "/map" })}>
        <img src="/images/menu_map.svg" alt="map" />
      </Link>
      <Link
        to="/notiffications"
        className={cx(tab, { active: pathname === "/notiffications" })}>
        <img src="/images/menu_notification.svg" alt="notf" />
      </Link>
      <Link
        to="/settings"
        className={cx(tab, {
          active: pathname === "/settings" || pathname === "/new-vessel"
        })}>
        <img src="/images/menu_settings.svg" alt="settings" />
      </Link>
    </div>
  )
}

export default Footer
