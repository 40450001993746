import React, { useState, useContext } from "react"
import { useSelector } from "react-redux"
import { IReduxStore } from "../../redux/reducers"
import { Redirect } from "react-router"
import {
  loginContainer,
  logoCont,
  btnClass,
  formContainer,
  errClass,
  forgotPassword
} from "./css"
import TextField from "../../components/TextField"
import Button from "../../components/Button"
import { useDispatch } from "react-redux"
import { login } from "../../redux/actions/account"
import I18n from "../../I18n"
import { parseJwt } from "../../utils"
import { Link } from "react-router-dom"

interface ICreds {
  username: string
  password: string
}

function callToLogin(creds: ICreds) {
  return fetch("/Appoploo2/api/auth/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "cache-control": "no-cache,no-cache"
    },
    body: JSON.stringify(creds)
  })
    .then((res) => {
      if (res.status > 400) throw new Error("err")
      return res.json()
    })
    .catch((reason) => {
      console.error(reason)
    })
}

function Login() {
  const account = useSelector((store: IReduxStore) => store.account)
  const [username, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [err, setErr] = useState(false)
  const dispatch = useDispatch()
  const t = useContext(I18n)
  return account.token ? (
    <Redirect to="/" />
  ) : (
    <div className={loginContainer}>
      <img
        className={logoCont}
        src="/images/appoploo_tracker_logo.png"
        alt="logo"
      />
      <form
        onSubmit={(e) => {
          e.preventDefault()
          if (password === "" || username === "") return
          return void callToLogin({ username, password })
            .then((info) => {
              dispatch(login({ ...info, ...parseJwt(info.token) }))
            })
            .catch((_err) => setErr(true))
        }}
        className={formContainer}>
        <TextField
          onChange={(e) => setUserName(e.currentTarget.value)}
          label={"Email"}
        />
        <br />

        <TextField
          onChange={(e) => setPassword(e.currentTarget.value)}
          type="password"
          label={"Password"}
        />

        <br />
        <br />
        <Button className={btnClass} type="primary">
          {t("login")}
        </Button>
        <br />
        <Link to="/forgot-password" className={forgotPassword}>
          {t("Remind password")}
        </Link>
      </form>
      <br />

      {err && (
        <p className={errClass}>
          {t("You have entered an invalid username or password")}
        </p>
      )}
    </div>
  )
}
export default Login
