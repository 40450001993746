import React, { useEffect, useState } from "react"
import { container } from "./css"
import { RouterProps } from "react-router"
import { useSelector, useDispatch } from "react-redux"
import { IReduxStore } from "../../redux/reducers"
import { getVessels } from "../../redux/actions/vessels"
import ListItem from "../../components/ListItem"
import { cx } from "emotion"

interface IProps extends RouterProps {}

function Vessel(props: IProps) {
  const _vessels = useSelector((state: IReduxStore) => state.vessels)

  const [selected, setSelected] = useState<number>()

  const vessels =
    _vessels.filter((v) => v.devices && v.devices.length > 0) || []
  const dispatch = useDispatch()

  useEffect(() => {
    const polling = setInterval(() => {
      dispatch(getVessels())
    }, 10000)
    dispatch(getVessels())
    return () => clearInterval(polling)
  }, [])

  return (
    <div className={container}>
      {vessels.map((vessel: any, idx) => {
        return (
          <div
            key={idx}
            onClick={() => setSelected(idx === selected ? undefined : idx)}>
            <ListItem
              className={cx({ selected: idx === selected })}
              {...vessel}
            />
          </div>
        )
      })}
    </div>
  )
}

export default Vessel
