import { AnyAction } from "redux"
import { SET_VESSELS } from "../names"

interface IVesselType {
  id: number
  vesselType: string
}
export interface IVessels {
  id: number
  vesselType?: IVesselType
  name?: string
  devices?: any[]
}

export const initVessels = []

function vessels(state: IVessels[] = initVessels, action: AnyAction) {
  switch (action.type) {
    case SET_VESSELS:
      return action.payload
    default:
      return state
  }
}

export default vessels
