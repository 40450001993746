import React, { useEffect, useState, useContext } from "react"
import { RouterProps, Redirect } from "react-router"
import Leaflet from "leaflet"
import { mapClass, headerCont, sectionClass, info, btn } from "./css"
import { useSelector, useDispatch } from "react-redux"
import { IReduxStore } from "../../redux/reducers"
import { footer } from "./css"
import Button from "../../components/Button"
import { cx } from "emotion"
import { subDays } from "date-fns"
import { getVesselRoute } from "../../redux/actions/currentVessel"
import I18n from "../../I18n"

const GreceCoords = {
  lat: 37.98381,
  lng: 23.727539
}

interface IProps extends RouterProps {}

function VesselInfo(props: IProps) {
  const account = useSelector((store: IReduxStore) => store.account)
  const vessel = useSelector((state: IReduxStore) => state.currentVessel)
  const [active, setActive] = useState<number | undefined>(undefined)
  const [map, setMap] = useState<Leaflet.Map | undefined>(undefined)

  const devices =
    vessel && vessel.devices && vessel.devices.length > 0 ? vessel.devices : []

  const firstDevice = devices[0] || {}
  const IMEI = firstDevice.deviceKey || ""
  const dispatch = useDispatch()

  const position =
    (firstDevice &&
      firstDevice.telematicsData &&
      firstDevice.telematicsData.position) ||
    {}
  const name = vessel.name || ""
  const speed = position.speed * 0.539957 || 0

  const _volt = (position.attributes && position.attributes.power) || 0
  const volt = (_volt / 1000).toFixed(2)

  const pos = {
    latitude: position.latitude || GreceCoords.lat,
    longitude: position.longitude || GreceCoords.lng
  }

  function initMap() {
    const [lat, lng] = [pos.latitude, pos.longitude]

    const _map = map || Leaflet.map("mapid").setView([lat, lng], 14)
    _map.eachLayer(layer => layer.remove())
    setMap(_map)
    Leaflet.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 18
    }).addTo(_map)
    const marker = Leaflet.marker([pos.latitude, pos.longitude], {}).addTo(_map)
    marker.bindPopup(`<b>${vessel.name}</b>`).openPopup()
  }

  const rotate = position.course

  useEffect(() => {
    initMap()
  }, [])

  useEffect(() => {
    if (!map) return void 0
    initMap()
    const { routes } = vessel
    if (routes.length < 1) return
    Leaflet.polyline(routes, { color: "red" }).addTo(map)
    const bounds = new Leaflet.LatLngBounds(routes)
    map.fitBounds(bounds)
  }, [vessel])

  const t = useContext(I18n)
  return account.token ? (
    <div>
      <div className={headerCont}>
        <b onClick={() => props.history.goBack()}>←</b>
        <h2>{name}</h2>
      </div>

      <div className={mapClass} id="mapid" />
      <div className={footer}>
        <div className={sectionClass}>
          <div className={info}>
            <label htmlFor="">{t("Speed")}</label>
            <h2>{speed.toFixed(2)} kts</h2>
          </div>
          <Button
            className={cx(btn, { active: active === 0 })}
            onClick={() => {
              setActive(0)
              dispatch(
                getVesselRoute({
                  IMEI,
                  from: subDays(Date.now(), 1).getTime()
                })
              )
            }}>
            {t("Today")}
          </Button>
        </div>
        <div className={sectionClass}>
          <div className={info}>
            <label htmlFor="">{t("Volt")}</label>
            <h2>{volt} V</h2>
          </div>
          <Button
            className={cx(btn, { active: active === 1 })}
            onClick={() => {
              setActive(1)

              dispatch(
                getVesselRoute({
                  IMEI,
                  from: subDays(Date.now(), 3).getTime()
                })
              )
            }}>
            3 {t("days")}
          </Button>
        </div>
        <div className={sectionClass}>
          <div className={info}>
            <img
              style={{ transform: `rotate(${rotate}deg)` }}
              src="/images/compass_2.svg"
              alt=""
            />
          </div>

          <Button
            className={cx(btn, { active: active === 2 })}
            onClick={() => {
              setActive(2)

              dispatch(
                getVesselRoute({
                  IMEI,
                  from: subDays(Date.now(), 7).getTime()
                })
              )
            }}>
            {t("Week")}
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to="/login" />
  )
}
export default VesselInfo
