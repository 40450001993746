import React from "react"
import Routes from "../routes"
import { layoutContainer, main, header, footer } from "./css"
import { cx } from "emotion"
import Footer from "../components/Footer"
import { useSelector } from "react-redux"
import { IReduxStore } from "../redux/reducers"
import { Redirect, RouterProps } from "react-router"
import Header from "../components/Header"

export interface ILayout {
  layoutClass: string
}

interface IProps extends RouterProps {}

function Layout(props: IProps) {
  const account = useSelector((store: IReduxStore) => store.account)
  return account.token ? (
    <div className={cx(layoutContainer)}>
      <Header layoutClass={header} />
      <Routes layoutClass={main} />
      <Footer {...props} layoutClass={footer} />
    </div>
  ) : (
    <Redirect to="/login" />
  )
}

export default Layout
