import React from "react"
import { boxInfo, container, img } from "./css"

interface IProps {
  approach: boolean
  dateText: string
  deviceKey: string
  geoObjectName: string
  id: number
  message: string
  timestamp: number
  vesselId: number
  vesselName: string
}

function NotificationItem(props: IProps) {
  const dir = props.approach ? `arrival` : `departure`
  const msg = props.approach ? "arrived at" : "departed from"
  return (
    <div className={container}>
      <img className={img} src={`/images/${dir}.svg`} alt="" />
      <div className={boxInfo}>
        <div>
          <strong>{props.vesselName}</strong> {msg}{" "}
          <strong>{props.geoObjectName}</strong>
        </div>
        <div>
          at <strong>{props.dateText}</strong>
        </div>
      </div>
    </div>
  )
}

export default NotificationItem
