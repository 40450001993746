import { css } from "emotion"

export const container = css`
  border-bottom: solid 1px #0005;
  display: flex;
  padding: 10px;
  align-items: center;
  width: calc(100% - 20px);
  height: 45px;

  opacity: 0.5;
  &.active {
    opacity: 1;
  }
`

export const img = css`
  margin-right: 15px;
  width: 30px;
  height: 30px;
  &.small {
    width: 20px;
    height: 20px;
  }
  cursor: pointer;
`
export const boxInfo = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  flex: 1;
  margin-right: 10px;
  font-size: normal;
`

export const btnCont = css`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

export const modalClass = css`
  width: 75vw;
  padding: 20px;
  &.camera {
    background: transparent;
  }
`
export const header = css`
  font-size: small;
  margin-top: 5px;
`

export const btn = css`
  outline: none;
  margin-left: 5px;
  border: none;
`
export const cameraClass = css`
  * {
    box-shadow: none !important;
    border: none !important;
  }
`
