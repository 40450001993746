import { css } from "emotion"

export const container = css`
  height: 100%;
  position: relative;
`

export const selected = css`
  background: #f00;
`
export const floatButton = css`
  position: absolute;
  font-size: xx-large;
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  background: var(--dark--green);
  outline: none;
`
