import { createStore, compose, applyMiddleware } from "redux"
import reducers, { IReduxStore } from "./reducers"
import { loadState, saveState } from "./localStorage"

import createSagaMiddleware from "redux-saga"
import rootSaga from "./sagas"
const sagaMiddleware = createSagaMiddleware()

const persistedData = loadState() as IReduxStore

const composeEnhancers =
  //@ts-ignore

  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    //@ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25
    })) ||
  compose
const ench =
  process.env.NODE_ENV === "development"
    ? composeEnhancers(applyMiddleware(sagaMiddleware))
    : applyMiddleware(sagaMiddleware)
const store = createStore(reducers, persistedData, ench)

store.subscribe(() => {
  saveState(store.getState())
})

sagaMiddleware.run(rootSaga)

export default store
