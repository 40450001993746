import { css } from "emotion"

const breakpoints = [576, 768, 992, 1200]

export const container = css`
  display: flex;
  justify-content: center;
  width: 100vw;
`

export const wrapper = css`
  width: 100%;
`

export const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`)
