import { css } from "emotion"

export const container = css`
  padding-top: 15px;
  height: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const header = css`
  margin-top: 20px;
  text-align: center;
`
export const wrapper = css`
  display: grid;
  padding: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 35px;
  grid-template-areas:
    "name name"
    "type type"
    "desc desc"
    "loa draught";
`

export const gridNameArea = css`
  grid-area: name;
`
export const gridTypeArea = css`
  grid-area: type;
`
export const gridDescArea = css`
  grid-area: desc;
`
export const gridLoaArea = css`
  grid-area: loa;
`
export const gridDraughtArea = css`
  grid-area: draught;
`

export const btnCont = css`
  display: flex;
  justify-content: space-between;
  padding: 0 5px 0 5px;
`

export const btn = css`
  align-self: flex-end;
  min-width: calc(50% - 5px);
  border-radius: 7px;
  font-weight: 600;
  &.set {
    background: #000;
    color: #fff;
  }
  a {
    width: 100%;
    height: 100%;
  }
`
