import { css } from "emotion"

export const layoutContainer = css`
  display: grid;
  height: 100vh;
  grid-template-areas:
    "header header header"
    "main main main"
    "footer footer footer";
  overflow: hidden;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 60px 1fr 60px;
  transition: 0.25s;
  width: 100%;
`

export const header = css`
  grid-area: header;
`

export const main = css`
  z-index: 0;
  grid-area: main;
  overflow-y: auto;
  min-height: calc(100vh - 121px);
`

export const footer = css`
  grid-area: footer;
`
