import { css } from "emotion"

export const container = css`
  border-bottom: solid 1px #0005;
  display: flex;
  padding: 10px;
  align-items: center;
  width: calc(100% - 20px);
  height: 45px;
  &.selected {
    background: #f00;
  }
`

export const img = css`
  margin-right: 15px;
  width: 35px;
  &.location {
    cursor: pointer;

    margin-left: auto;
  }

  &.compass {
    width: 25px;
  }
`
export const boxInfo = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  flex: 1;
  margin-right: 10px;
  font-size: normal;
  p {
    font-size: xx-small;
  }
`
