import React, { useState, useContext } from "react"
import { useSelector } from "react-redux"
import { IReduxStore } from "../../redux/reducers"
import { Redirect } from "react-router"
import {
  loginContainer,
  logoCont,
  successClass,
  btnClass,
  formContainer,
  errClass,
  forgotPassword
} from "./css"
import TextField from "../../components/TextField"
import Button from "../../components/Button"
import I18n from "../../I18n"
import { Link } from "react-router-dom"

function callToLogin(email: string) {
  return fetch(`/Appoploo2/resetPasswd?email=${email}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "cache-control": "no-cache,no-cache"
    }
  })
}

function Forgot() {
  const [email, setUserName] = useState("")
  const [cEmail, setCEmail] = useState("")
  const [err, setErr] = useState(false)
  const [success, setSucess] = useState(false)
  const t = useContext(I18n)
  return (
    <div className={loginContainer}>
      <img
        className={logoCont}
        src="/images/appoploo_tracker_logo.png"
        alt="logo"
      />
      <form
        onSubmit={(e) => {
          e.preventDefault()
          if (email === "") return
          return void callToLogin(email)
            .then((res) => {
              if (res.status < 400) {
                setCEmail(email)
                setUserName("")
                setSucess(true)
                setErr(false)
              } else {
                setErr(true)
                setSucess(false)
              }
            })
            .catch((_err) => {})
        }}
        className={formContainer}>
        <TextField
          onChange={(e) => setUserName(e.currentTarget.value)}
          label={"Email"}
        />
        <br />
        <br />
        <Button className={btnClass} type="primary">
          {t("Remind password")}
        </Button>
        <br />
        <Link to="/login" className={forgotPassword}>
          {t("return to login")}
        </Link>
      </form>
      <br />

      {success && (
        <p className={successClass}>{t(`A message was sent to ${cEmail}`)}</p>
      )}

      {err && (
        <p className={errClass}>{t("You have entered an invalid email")}</p>
      )}
    </div>
  )
}
export default Forgot
