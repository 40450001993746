import React, { useEffect, useState } from "react"
import { container, floatButton } from "./css"
import { RouterProps } from "react-router"
import { useSelector, useDispatch } from "react-redux"
import { IReduxStore } from "../../redux/reducers"
import { getVessels } from "../../redux/actions/vessels"
import Item from "../../components/Item"
import Button from "../../components/Button"
import { Link } from "react-router-dom"

function Settings() {
  const vessels = useSelector((state: IReduxStore) => state.vessels) || []
  const dispatch = useDispatch()
  useEffect(() => {
    const polling = setInterval(() => {
      dispatch(getVessels())
    }, 10000)
    dispatch(getVessels())
    return () => clearInterval(polling)
  }, [])

  return (
    <div className={container}>
      {vessels.map((vessel: any, idx) => {
        const type = vessel.vesselType && vessel.vesselType.vesselType
        const id = vessel.id
        const name = vessel.name || ""
        return (
          <Item
            device={vessel.devices}
            key={idx}
            id={id}
            type={type}
            name={name}
          />
        )
      })}
      <Link to="/new-vessel">
        <Button className={floatButton} type="float">
          +
        </Button>
      </Link>
    </div>
  )
}

export default Settings
