import account, { IAccount } from "./account"
import { combineReducers, AnyAction } from "redux"
import { LOGOUT } from "../names"
import vessels, { IVessels } from "./vessels"
import notifications, { INotifications } from "./notifications"
import currentVessel, { ICurrentVessel } from "./currentVessel"

export interface IReduxStore {
  account: IAccount
  vessels: IVessels[]
  currentVessel: ICurrentVessel
  notifications: INotifications[]
}

const appReducer = combineReducers({
  account,
  currentVessel,
  vessels,
  notifications
})

const rootReducer = (state: IReduxStore | undefined, action: AnyAction) => {
  if (action.type === LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
