import { AnyAction } from "redux"
import { SET_NOTIFICATIONS } from "../names"

export interface INotifications {}

export const initNotification = []

function notifications(
  state: INotifications[] = initNotification,
  action: AnyAction
) {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return action.payload
    default:
      return state
  }
}

export default notifications
