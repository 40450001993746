import React, { useState, useContext } from "react"
import {
  container,
  img,
  boxInfo,
  modalClass,
  header,
  btn,
  cameraClass
} from "./css"
import { cx } from "emotion"
import Modal from "react-responsive-modal"
import I18n from "../../I18n"
import Button from "../Button"
import { btnCont } from "./css"
import { useDispatch } from "react-redux"
import { deleteVessel, connectVessel } from "../../redux/actions/vessels"
import QrReader from "react-qr-reader"

interface IProps {
  name: string
  type: string
  id: string
  device: Record<string, any>[]
}

function Item(props: IProps) {
  const [deletModal, setDeleteModal] = useState(false)
  const [scanModal, setScanModal] = useState(false)
  const t = useContext(I18n)
  const dispatch = useDispatch()

  function handleScan(data: string | null) {
    if (data) {
      const [IMEI, _] = data.split(".")
      dispatch(connectVessel({ vesselID: props.id, IMEI }))
      setScanModal(false)
    }
  }

  return (
    <div className={cx(container, { active: props.device.length > 0 })}>
      <img className={img} src="/images/boat.svg" alt="" />
      <div className={boxInfo}>
        <h3>{props.name}</h3>
        <p>{props.type}</p>
      </div>
      <div>
        <img
          onClick={() => setScanModal(true)}
          className={cx(img, "small")}
          src="/images/link.svg"
          alt=""
        />
        <img
          onClick={() => setDeleteModal(true)}
          className={cx(img, "small")}
          src="/images/delete.svg"
          alt=""
        />
      </div>
      <Modal
        classNames={{
          modal: modalClass
        }}
        open={deletModal}
        onClose={() => setDeleteModal(false)}
        center>
        <h3>{t("Attention!")}</h3>
        <div className={header}>
          {t("Delete vessel")}: {props.name}
        </div>
        <br />
        <div className={btnCont}>
          <Button className={btn} type="danger">
            {t("NO")}
          </Button>
          <Button
            onClick={() => {
              dispatch(deleteVessel({ vessel_id: props.id }))
              setDeleteModal(false)
            }}
            className={btn}
            type="danger">
            {t("YES")}
          </Button>
        </div>
      </Modal>
      <Modal
        classNames={{
          modal: cx(modalClass, "camera")
        }}
        open={scanModal}
        onClose={() => {
          setScanModal(false)
        }}
        center>
        <QrReader
          delay={300}
          onError={console.log}
          onScan={handleScan}
          style={{ width: "100%" }}
        />
      </Modal>
    </div>
  )
}

export default Item
