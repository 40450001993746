import { AnyAction } from "redux"
import { SET_CURRENT_VESSEL, SET_VESSEL_ROUTE } from "../names"

export interface ICurrentVessel extends Record<string, any> {}

export const initCurrentVessel = {}

function currentVessel(
  state: ICurrentVessel = initCurrentVessel,
  action: AnyAction
) {
  switch (action.type) {
    case SET_CURRENT_VESSEL:
      return action.payload

    case SET_VESSEL_ROUTE:
      return { ...state, routes: action.payload }

    default:
      return state
  }
}

export default currentVessel
