import { css } from "emotion"

export const container = css`
  height: 60px;
  width: 100%;
  display: flex;
  background: var(--dark-blue);
`

export const tab = css`
  outline: none;
  background: transparent;
  transition: background 0.125s;
  border: none;
  cursor: pointer;
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    background: var(--green);
    border-bottom: solid 5px red;
  }
  img {
    width: 20px;
    height: 20px;
  }
`
