import React, { DetailedHTMLProps, useState, useCallback } from "react"
import { inputCont, labelClass, inputClass } from "./css"
import { cx } from "emotion"

interface IProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  type?: string
  label?: string
  readOnly?: boolean
  required?: boolean
  min?: number
}
function TextField(props: IProps) {
  const [value, setValue] = useState(props.defaultValue)
  const [focus, setFocus] = useState(false)
  const [error, setError] = useState(false)

  const handleChange = useCallback(
    evt => {
      const value = evt.currentTarget.value
      props.onChange && props.onChange(evt)
      setValue(value)
    },
    [props]
  )

  const handleFocus = useCallback(() => {
    setFocus(true)
    setError(false)
  }, [])
  const handleBlur = useCallback(e => {
    if (props.required && `${e.currentTarget.value}`.length === 0)
      setError(true)
    setFocus(false)
  }, [])

  return (
    <div className={cx(inputCont, props.className)}>
      <label
        className={cx(labelClass, {
          filled: Boolean(value) || focus || Boolean(props.defaultValue),
          error
        })}>
        {props.label}
      </label>

      <input
        {...props}
        type={props.type || "text"}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        className={cx(inputClass, { error })}
      />
    </div>
  )
}

export default TextField
