import { IReduxStore } from "../reducers"
import { put, select } from "redux-saga/effects"
import { logout, updateToken } from "../actions/account"

const ERROR_STATUSES: Array<number> = [400, 401, 403, 404, 415, 500]
const getRefreshToken = (state: IReduxStore) => state.account.refreshToken || ""

export const selectAccessToken = (state: IReduxStore) => state.account.token
export const selectAccessTokenExp = (state: IReduxStore) => state.account.exp
export const getTenant = (state: IReduxStore) => state.account.tenant

export function* getAccessToken() {
  const accessTokenExp = yield select(selectAccessTokenExp)
  const access_token = yield select(selectAccessToken)
  const refreshToken = yield select(getRefreshToken)
  const tenant = yield select(getTenant)
  if (accessTokenExp && Number(accessTokenExp) * 1000 < Date.now()) {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-Authorization": `Bearer ${refreshToken}`,
        "cache-control": "no-cache,no-cache",
        "tenant-id": tenant
      } as Record<string, any>

      const res = yield fetch("/Appoploo2/api/auth/token", { headers })
      const { token = "" } = yield res.json()

      yield put(updateToken(token))
      return token
    } catch (e) {
      console.error(e)
      yield put(logout())
    }
  } else {
    return access_token
  }
}

export function* apiCall(
  END_POINT: string,
  METHOD: string,
  HEADERS: Record<string, string>,
  BODY?: BodyInit
) {
  const access_token = yield getAccessToken()

  HEADERS["X-Authorization"] = `Bearer ${access_token}`

  const config: RequestInit = {
    method: METHOD,
    headers: HEADERS,
    body: BODY
  }

  const data: Response = yield fetch(END_POINT, config)

  if ("error" in data || ERROR_STATUSES.includes(data.status)) {
    throw data
  }
  return data
}
