import React from "react"
import { Switch, Route } from "react-router"
import Layout from "./Layout"
import Login from "./routes/Login"

import { container, wrapper } from "./css"
import VesselInfo from "./routes/VesselInfo"
import Forgot from "./routes/Forgot"

function App() {
  return (
    <main className={container}>
      <div className={wrapper}>
        <Switch>
          <Route path={"/vessel/:id"} component={VesselInfo} />
          <Route path="/forgot-password" component={Forgot} />
          <Route path="/login" component={Login} />
          <Route path="/" component={Layout} />
        </Switch>
      </div>
    </main>
  )
}

export default App
