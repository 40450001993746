import { createAction } from "redux-actions"
import {
  GET_VESSELS,
  SET_VESSELS,
  CREATE_VESSEL,
  DELETE_VESSEL,
  CONNECT_VESSEL
} from "../names"

export const getVessels = createAction(GET_VESSELS)
export const setVessels = createAction(SET_VESSELS)
export const createVessel = createAction(CREATE_VESSEL)
export const deleteVessel = createAction(DELETE_VESSEL)
export const connectVessel = createAction(CONNECT_VESSEL)
