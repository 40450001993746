import { all } from "redux-saga/effects"
import vesselsWatcher from "./vessels"
import notificationsWatcher from "./notifications"

const watchers = [vesselsWatcher, notificationsWatcher]

const WATCHERS = watchers.map(watcher => watcher())

export default function* rootSaga() {
  yield all(WATCHERS)
}
