import React from "react"
import { ILayout } from "../Layout"
import { Switch, Route } from "react-router"
import Vessels from "./Vessels"
import Notifications from "./Notification"
import Settings from "./Settings"
import Map from "./Map"
import NewVessel from "./NewVessel"

interface IMainRoutes extends ILayout {}

function Routes(props: IMainRoutes) {
  return (
    <main className={props.layoutClass}>
      <Switch>
        <Route exact path={"/"} component={Vessels} />
        <Route path={"/map"} component={Map} />
        <Route path={"/notiffications"} component={Notifications} />
        <Route path={"/settings"} component={Settings} />
        <Route path={"/new-vessel"} component={NewVessel} />
      </Switch>
    </main>
  )
}

export default Routes
