import { select, takeLatest, put } from "redux-saga/effects"
import { apiCall } from "./network"
import { GET_NOTIFICATIONS } from "../names"
import { AnyAction } from "redux"
import { IReduxStore } from "../reducers"
import { setNotifications } from "../actions/notifications"
import { subMonths } from "date-fns"

const URL = "Appoploo2/notifications?from="

export const getTenant = (state: IReduxStore) => state.account.tenant

function* getNotifications(_action: AnyAction) {
  const tenant = yield select(getTenant)
  const sixMonthBefor = subMonths(Date.now(), 4).getTime()
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache,no-cache",
    "tenant-id": tenant
  }
  const url = `${URL}${sixMonthBefor}`

  try {
    const res = yield apiCall(url, "GET", headers)
    const response = yield res.json()
    yield put(setNotifications(response))
  } catch (error) {
    console.error(error)
  }
}

function* notificationsWatcher() {
  yield takeLatest(GET_NOTIFICATIONS, getNotifications)
}

export default notificationsWatcher
