import React, { useContext, useState } from "react"
import I18n from "../../I18n"
import Button from "../../components/Button"
import TextField from "../../components/TextField"
import Popover from "../../components/Popover"
import PopOverItem from "../../components/Popover/Item"
import {
  container,
  wrapper,
  gridNameArea,
  gridTypeArea,
  gridDescArea,
  gridLoaArea,
  gridDraughtArea,
  header,
  btnCont,
  btn
} from "./css"
import { cx } from "emotion"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { createVessel } from "../../redux/actions/vessels"

type vesselType = "Motor Yacht" | "Sailing Yacht" | "Catamaran" | "Power boat"

const mapVesselTypeToValue: Record<vesselType, string> = {
  "Motor Yacht":
    "http://server.cruiser.gr:8290/Appoploo2/api/rest/vesselTypes/1",
  "Sailing Yacht":
    "http://server.cruiser.gr:8290/Appoploo2/api/rest/vesselTypes/2",
  Catamaran: "http://server.cruiser.gr:8290/Appoploo2/api/rest/vesselTypes/3",
  "Power boat": "http://server.cruiser.gr:8290/Appoploo2/api/rest/vesselTypes/4"
}

function NewVessel() {
  const t = useContext(I18n)
  const [state, setState] = useState({
    name: "",
    vesselType: "",
    description: "",
    loa: 0,
    draught: 0
  })
  const dispatch = useDispatch()

  return (
    <div className={container}>
      <div>
        <h1 className={header}>{t("New vessel info")}</h1>
        <br />
        <br />
        <div className={wrapper}>
          <div className={gridNameArea}>
            <TextField
              required
              onChange={e => {
                const name = e.currentTarget.value
                setState(s => ({ ...s, name }))
              }}
              title={t("Please add a vessel name")}
              label={t("Vessel name")}
            />
          </div>
          <div className={gridTypeArea}>
            <Popover
              value={state.vesselType}
              label={
                <TextField
                  readOnly
                  label={t("Yacht vesselType")}
                  defaultValue={state.vesselType}
                />
              }>
              <PopOverItem
                onClick={vesselType =>
                  setState(s => ({
                    ...s,
                    vesselType: `${vesselType.currentTarget.textContent}`
                  }))
                }>
                {t("Motor Yacht")}
              </PopOverItem>
              <PopOverItem
                onClick={vesselType =>
                  setState(s => ({
                    ...s,
                    vesselType: `${vesselType.currentTarget.textContent}`
                  }))
                }>
                {t("Sailing Yacht")}
              </PopOverItem>
              <PopOverItem
                onClick={vesselType =>
                  setState(s => ({
                    ...s,
                    vesselType: `${vesselType.currentTarget.textContent}`
                  }))
                }>
                {t("Catamaran")}
              </PopOverItem>
              <PopOverItem
                onClick={vesselType =>
                  setState(s => ({
                    ...s,
                    vesselType: `${vesselType.currentTarget.textContent}`
                  }))
                }>
                {t("Power boat")}
              </PopOverItem>
            </Popover>
          </div>
          <div className={gridDescArea}>
            <TextField
              required
              onChange={e => {
                const description = e.currentTarget.value
                setState(s => ({ ...s, description }))
              }}
              title={t("Please add a Vessel description")}
              label={t("Vessel description")}
            />
          </div>
          <div className={gridLoaArea}>
            <TextField
              required
              onChange={e => {
                const loa = +e.currentTarget.value
                setState(s => ({ ...s, loa }))
              }}
              type="number"
              min={0}
              title={t("Please add a Vessel length")}
              label={t("Length (LOA)")}
            />
          </div>
          <div className={gridDraughtArea}>
            <TextField
              required
              onChange={e => {
                const draught = +e.currentTarget.value
                setState(s => ({ ...s, draught }))
              }}
              type="number"
              min={0}
              title={t("Please add a Vessel draught")}
              label={t("Draught")}
            />
          </div>
        </div>
      </div>

      <div className={btnCont}>
        <Button className={btn}>
          <Link to="/settings">{t("CANCEL")}</Link>
        </Button>
        <Button
          onClick={() => {
            const transformedState = {
              ...state,
              vesselType: mapVesselTypeToValue[state.vesselType as vesselType]
            }
            dispatch(createVessel(transformedState))
          }}
          className={cx(btn, "set")}>
          <Link to="/settings">{t("SET")}</Link>
        </Button>
      </div>
    </div>
  )
}

export default NewVessel
