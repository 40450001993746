import { css } from "emotion"

export const mapClass = css`
  height: 70vh;
  width: 100%;
`

export const headerCont = css`
  height: 60px;
  background: var(--dark-blue);
  width: calc(100% - 42px);
  padding: 0 20px 0 20px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #0002;
  color: #fff;
  b {
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-size: xx-large;
    margin-right: 15px;
  }
`

export const footer = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: solid 1px #0005;
  height: calc(100vh - 70vh - 62px);
  background: #fefefe;
`

export const sectionClass = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    align-self: center;
    flex: 1;
  }
`

export const info = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const btn = css`
  outline: none;
  &.active {
    color: white;

    background: var(--dark-blue);
  }
`
