import React from "react"
import { container, img, boxInfo } from "./css"
import { cx } from "emotion"
import { formatDistance } from "date-fns"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setCurrentVessel } from "../../redux/actions/currentVessel"

type Pos = {
  latitude: number
  longitude: number
}
interface IProps extends Record<string, any> {
  name: string
  speed: string | number
  volt: string | number
  date: string | number
  pos: Pos
  className?: string
  id?: string
}

function ListItem(props: IProps) {
  const device = props.devices && props.devices[0]
  const telematicsData = device && device.telematicsData
  const telematicsDataDevice = telematicsData && telematicsData.device
  const position = (telematicsData && telematicsData.position) || {}
  const dispatch = useDispatch()

  const name = props.name || ""
  const speed = position.speed * 0.539957 || 0
  const _volt = (position.attributes && position.attributes.power) || 0
  const volt = (_volt / 1000).toFixed(1)

  const rotate = position.course

  const lastUpdate = telematicsDataDevice
    ? formatDistance(new Date(telematicsDataDevice.lastUpdate), new Date())
    : ""

  const displayLastUpdate =
    lastUpdate === `less than a minute`
      ? "Just now"
      : lastUpdate === ``
      ? ""
      : `${lastUpdate} ago`

  return (
    <div className={cx(container, props.className)}>
      <img className={img} src="/images/boat.svg" alt="" />
      <div className={boxInfo}>
        <h3>{name}</h3>
        <p>{displayLastUpdate} </p>
      </div>
      <img
        className={cx(img, "compass")}
        style={{ transform: `rotate(${rotate}deg)` }}
        src="/images/compass_2.svg"
        alt=""
      />
      <div className={cx(boxInfo, "infos")}>
        <div>{speed.toFixed(1)} kts</div>
        <div>{volt} V</div>
      </div>
      <Link to={`/vessel/${props.id}`}>
        <img
          onClick={() => dispatch(setCurrentVessel({ ...props }))}
          className={cx(img, "location")}
          src="/images/target.svg"
          alt=""
        />
      </Link>
    </div>
  )
}

export default ListItem
