import React, { useContext } from "react"
import { ILayout } from "../../Layout"
import { headerCont, optionClass, infoClass, imgClass, avatar } from "./css"
import { cx } from "emotion"
import { useDispatch } from "react-redux"
import I18n from "../../I18n"
import IconButton from "../IconButton"
import Avatar from "../Avatar"
import Popover from "../Popover"
import PopOverItem from "../Popover/Item"
import { logout } from "../../redux/actions/account"

interface IProps extends ILayout {}
function Header(props: IProps) {
  const t = useContext(I18n)
  const dispatch = useDispatch()

  return (
    <header className={cx(props.layoutClass, headerCont)}>
      <h2>Appoploo Plus</h2>

      <Popover label={<Avatar className={avatar} src="/images/more.svg" />}>
        <PopOverItem>
          <div onClick={() => dispatch(logout())} className={optionClass}>
            <IconButton className={imgClass} src="/images/logout.svg" />
            <span className={infoClass}>{t("Log out")}</span>
          </div>
        </PopOverItem>
      </Popover>
    </header>
  )
}
export default Header
