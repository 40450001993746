import React, { useEffect } from "react"
import { container } from "./css"
import { RouterProps } from "react-router"
import { useSelector, useDispatch } from "react-redux"
import { IReduxStore } from "../../redux/reducers"
import { getNotifications } from "../../redux/actions/notifications"
import NotificationItem from "../../components/NotificationItem"
interface IProps extends RouterProps {}

function Notifications(props: IProps) {
  const notifications =
    useSelector((state: IReduxStore) => state.notifications) || []
  const dispatch = useDispatch()

  useEffect(() => {
    const polling = setInterval(() => {
      dispatch(getNotifications())
    }, 10000)
    dispatch(getNotifications())
    return () => clearInterval(polling)
  }, [])

  return (
    <div className={container}>
      {notifications.length > 0 &&
        notifications.map((o: any, idx) => (
          <NotificationItem key={idx} {...o} />
        ))}
    </div>
  )
}

export default Notifications
