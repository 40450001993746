import { IReduxStore } from "./reducers"

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("appoploo-client")
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}
export const saveState = (state: IReduxStore) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem("appoploo-client", serializedState)
  } catch (e) {} //eslint-disable-line
}
