import { createAction } from "redux-actions"
import {
  SET_CURRENT_VESSEL,
  SET_VESSEL_ROUTE,
  GET_VESSEL_ROUTE
} from "../names"

export const setCurrentVessel = createAction(SET_CURRENT_VESSEL)

export const setVesselRoute = createAction(SET_VESSEL_ROUTE)
export const getVesselRoute = createAction(GET_VESSEL_ROUTE)
