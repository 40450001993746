import { AnyAction } from "redux"
import { LOGIN, UPDATE_TOKEN } from "../names"

export interface IAccount {
  token?: string
  refreshToken?: string
  tenant?: string
  sub?: string
  iss?: string
  iat?: number
  exp?: number
}

export const initAccount = {
  token: undefined,
  refreshToken: undefined,
  tenant: undefined,
  sub: undefined,
  iss: undefined,
  iat: undefined,
  exp: undefined
}

function auth(state: IAccount = initAccount, action: AnyAction) {
  switch (action.type) {
    case LOGIN:
      return action.payload

    case UPDATE_TOKEN:
      return { ...state, token: action.payload }
    default:
      return state
  }
}

export default auth
