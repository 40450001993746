import { select, takeLatest, put } from "redux-saga/effects"
import { apiCall } from "./network"
import { getVessels as updateVessels } from "../actions/vessels"
import {
  GET_VESSELS,
  GET_VESSEL_ROUTE,
  CREATE_VESSEL,
  DELETE_VESSEL,
  CONNECT_VESSEL
} from "../names"
import { AnyAction } from "redux"
import { IReduxStore } from "../reducers"
import { setVessels } from "../actions/vessels"
import { setVesselRoute } from "../actions/currentVessel"
import { toast } from "react-toastify"

const polyline = require("google-polyline")

const URL = "Appoploo2/vessels"

export const getTenant = (state: IReduxStore) => state.account.tenant

function* getVessels(_action: AnyAction) {
  const tenant = yield select(getTenant)

  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache,no-cache",
    "tenant-id": tenant
  }

  try {
    const res = yield apiCall(URL, "GET", headers)
    const response = yield res.json()
    yield put(setVessels(response))
  } catch (error) {
    console.error(error)
  }
}

function* getVesselRoute(action: AnyAction) {
  const tenant = yield select(getTenant)
  const { IMEI = "", from = 0 } = action.payload

  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache,no-cache",
    "tenant-id": tenant
  }

  try {
    const res = yield apiCall(
      `/Appoploo2/route/${IMEI}?from=${from}`,
      "GET",
      headers
    )
    const response = yield res.text()
    const routes = polyline.decode(`${response}`)

    yield put(setVesselRoute(routes))
  } catch (error) {
    console.error(error)
  }
}

function* createVessel(action: AnyAction) {
  const tenant = yield select(getTenant)

  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache,no-cache",
    "tenant-id": tenant
  }

  try {
    const res = yield apiCall(
      `/Appoploo2/api/rest/vessels`,
      "POST",
      headers,
      JSON.stringify({
        engine: null,
        width: null,
        createdAt: new Date(),
        fuelTank: null,
        callsign: "",
        registryNumber: "",
        cabins: null,
        wc: null,
        pax: null,
        builtYear: null,
        waterTank: null,
        tareWeight: null,
        devices: [],
        updatedAt: new Date(),
        owner: null,
        ...action.payload,
        readings: {}
      })
    )
    toast.success(`Vessel created successfully`)
  } catch (error) {
    console.error(error)
    toast.error("Failed to create Vessel")
  }
}

function* connectVessel(action: AnyAction) {
  const tenant = yield select(getTenant)
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache,no-cache",
    "tenant-id": tenant
  }

  try {
    yield apiCall(
      `/Appoploo2/api/telematics/register`,
      "POST",
      headers,
      JSON.stringify(action.payload)
    )
    yield put(updateVessels())
    toast.success(`Vessel  registered successfully`)
  } catch (error) {
    console.error(error)
    toast.error("Failed to registered Vessel")
  }
}

function* deleteVesel(action: AnyAction) {
  const tenant = yield select(getTenant)
  const { vessel_id } = action.payload
  const headers = {
    "Content-Type": "application/json",
    "cache-control": "no-cache,no-cache",
    "tenant-id": tenant
  }

  try {
    yield apiCall(`/Appoploo2/api/rest/vessels/${vessel_id}`, "DELETE", headers)
    yield put(updateVessels())
  } catch (error) {
    console.error(error)
    toast.error("Failed to delete Vessel")
  }
}

function* vesselsWatcher() {
  yield takeLatest(GET_VESSELS, getVessels)
  yield takeLatest(GET_VESSEL_ROUTE, getVesselRoute)
  yield takeLatest(CREATE_VESSEL, createVessel)
  yield takeLatest(DELETE_VESSEL, deleteVesel)
  yield takeLatest(CONNECT_VESSEL, connectVessel)
}

export default vesselsWatcher
