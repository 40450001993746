export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const UPDATE_TOKEN = "Update Token"

export const GET_VESSELS = "Get Vessels"
export const SET_VESSELS = "Set Vessels"

export const GET_NOTIFICATIONS = "Get Notification"
export const SET_NOTIFICATIONS = "Set Notification"

export const SET_CURRENT_VESSEL = "Set Current Vessel"
export const CREATE_VESSEL = "Create Vessel"
export const DELETE_VESSEL = "Delete Vessel"

export const SET_VESSEL_ROUTE = "Set Vessel Route"
export const GET_VESSEL_ROUTE = "Get Vessel Route"
export const CONNECT_VESSEL = "Connect Vessel Route"
