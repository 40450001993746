import { css } from "emotion"

export const container = css`
  border-bottom: solid 1px #0005;
  display: flex;
  font-size: 14px;
  padding: 10px 0 10px 0;
  align-items: center;
  width: calc(100%);
  height: 45px;
`

export const img = css`
  margin-right: 15px;
  width: 30px;
`
export const boxInfo = css`
  height: 100%;
  flex: 1;
  margin-right: 10px;
  font-size: normal;
  font-weight: 300;
  line-height: 1.6;
`
