import { css } from "emotion"

export const inputCont = css`
  position: relative;
  height: 30px;
  width: 100%;
`
export const labelClass = css`
  position: absolute;
  left: 0px;
  bottom: 0px;
  transition: 0.17s;
  user-select: none;
  font-size: small;
  color: var(--grey);
  &.error {
    color: red;
  }
  &.filled {
    bottom: 15px;
    left: -10px;
    transform: scale3d(0.75, 0.75, 0.75);
    color: var(--green);
    &.error {
      color: red;
    }
  }
`

export const inputClass = css`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0px;
  outline: none;
  border-bottom: solid 1px var(--grey);
  &.error {
    color: red;
    border-bottom: solid 1px red;
  }
  &:focus {
    border-bottom: solid 1px var(--green);
  }
`
