import { css } from "emotion"

export const loginContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10vh 20px;
  width: calc(100% - 40px);
  background: #fff;
`

export const logoCont = css`
  height: 15vh;
  margin-bottom: 10vh;
`

export const btnClass = css`
  background: var(--dark-blue);
  outline: none;
  width: 100%;
  max-width: 100vw;
`

export const formContainer = css`
  width: 100%;
  height: 100%;
`

export const errClass = css`
  color: red;
  font-weight: 600;
`

export const successClass = css`
  color: #4ad4a3;
  font-weight: 600;
`
export const forgotPassword = css`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: small;
  color: #383d64 !important;
  color: blue;
  cursor: pointer;
`
